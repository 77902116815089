// Colors

$black: #303032 !default;
$white: #fff !default;

$grey: #b9b5b8 !default;

$grey-light: #ebeef1 !default;
$grey-lighter: #f5f5f5 !default;
$white-ter: #f7f7f7 !default;
$white-bis: #f5f7f9 !default;

$link: #4e5ca6 !default;
$link-light: #e4e5f1 !default;
$link-lighter: #f3f4f9 !default;

$success: #57b3a0 !default;
$danger: #f46470 !default;
$warning: #f8a94a !default;
$info: #1eb1e8 !default;

$success-light: rgba(#57b3a0, 0.1) !default;
$danger-light: rgba(#f46470, 0.1) !default;
$warning-light: rgba(#f8a94a, 0.1) !default;
$info-light: rgba(#1eb1e8, 0.1) !default;

$main-background: $white-bis !default;

$nav-item-hover: $link-light !default;
$nav-item-color: $black !default;
$side-nav-background: $white !default;

$field-background: $white-bis !default;
$field-background-hover: $white-bis !default;
$field-background-active: $white-bis !default;
$placeholder: #8d929d !default;
$field-border: $grey-light !default;
$border: $grey-light !default;
$field-border-focus: $link !default;
